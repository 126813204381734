import { BackendMethod } from '@memberspot/shared/model/backend';

import { AcceptDpaDto } from './dtos/accept-dpa.dto';
import { UpdateCommunitySettingsDto } from './dtos/update-community-settings.dto';

const SchoolEndpointsInternal = {
  ACCEPT_DPA: (schoolId: string, data: AcceptDpaDto) => ({
    url: `/schools/dpa/${schoolId}/accept`,
    data,
    method: BackendMethod.POST,
  }),
  DPA_LIST: (schoolId: string) => ({
    url: `/schools/dpa/${schoolId}`,
    method: BackendMethod.GET,
  }),
  DPA_DOWNLOAD: (schoolId: string, dpaId: string) => ({
    url: `/schools/dpa/${schoolId}/download/${dpaId}`,
    method: BackendMethod.POST,
  }),
  UPDATE_COMMUNITY_SETTINGS: (data: UpdateCommunitySettingsDto) => ({
    url: `/schools/update-community-settings/${data.schoolId}`,
    data,
    method: BackendMethod.PATCH,
  }),
};

export const SchoolEndpoints: Readonly<typeof SchoolEndpointsInternal> =
  SchoolEndpointsInternal;
